<template>
  <b-card no-body>
    <b-overlay :show="loading">
      <b-card-body>
        <b-card-title>
          <b-row>
            <b-col>
              <div>Bank Soal</div>
            </b-col>
            <b-col>
              <div align="right">
                <b-button
                  v-if="allowCreate()"
                  variant="outline-primary"
                  v-b-modal.modal-sm
                  class="btn-icon"
                  :to="{ name: `admin-tambah-bank-soal` }"
                >
                  <!-- @click="ModalShow()" -->
                  <feather-icon icon="PlusIcon" class="mr-25" />Tambah</b-button
                >
                <b-button
                  v-if="allowCreate()"
                  variant="outline-primary"
                  v-b-modal.modal-sm
                  class="btn-icon ml-1"
                  :to="{ name: `admin-tambah-pauli-bank-soal` }"
                >
                  <!-- @click="ModalShow()" -->
                  <feather-icon icon="PlusIcon" class="mr-25" />Tambah
                  Pauli</b-button
                >
              </div>
            </b-col></b-row
          >
        </b-card-title>
        <b-card-text class="blog-content-truncate">
          <b-row>
            <b-col sm="12" md="4" lg="4" class="">
              <label>Pilih Kategori Paket</label>
              <v-select
                label="text"
                v-model="filterKatPaket"
                class="select-size-sm w-full"
                :clearable="true"
                :options="optKatPaket"
              />
              <!-- @input="getDataPaket()" -->
            </b-col>
            <b-col sm="12" md="4" lg="4" class="">
              <label>Pilih Ujian</label>
              <v-select
                label="text"
                v-model="filterMapel"
                class="select-size-sm w-full"
                :clearable="true"
                :options="optMapel"
              />
            </b-col>
            <b-col sm="12" md="4" lg="4" class="">
              <label>Pilih Tipe Soal</label>
              <v-select
                label="value"
                v-model="filterTipe"
                class="select-size-sm w-full"
                :clearable="true"
                :options="optTipe"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3" sm="4" class="my-1" v-if="soalData.length >= 1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"
                  >Per page</label
                >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1" v-if="soalData.length >= 1">
              <b-form-group
                label="Sort"
                label-cols-sm="2"
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-50"
                  >
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                    class="w-30"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="5" class="my-1" v-if="soalData.length >= 1">
              <b-form-group label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Tekan enter untuk cari"
                    @keydown.enter.prevent="getData()"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="
                        filter = '';
                        getData();
                      "
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- <b-col md="2" class="my-1">
            <b-button variant="primary" size="sm"
              ><feather-icon icon="TrashIcon" />Multiple Delete</b-button
            >
          </b-col> -->

            <b-col cols="12">
              <b-table
                v-if="soalData.length >= 1"
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :fields="fields"
                :items="soalData"
              >
                <!-- :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered" -->
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <!-- A custom formatted column -->
                <template #cell(action)="row">
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      v-if="row.item.type != 'pauli' && allowUpdate()"
                      :to="{
                        path: `bank-soal/edit/${row.item.id}`,
                        query: {
                          ujian_id: row.item?.ujian_id,
                          cat: filterKatPaket ? filterKatPaket?.value : null,
                        },
                      }"
                    >
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="row.item.type == 'pauli' && allowUpdate()"
                      :to="{ path: `bank-soal/pauli/edit/${row.item.id}` }"
                    >
                      <feather-icon icon="EditIcon" class="mr-50" />
                      <span>Edit Pauli</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="allowDelete()"
                      @click="ModalHapus(row.item)"
                    >
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Hapus</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
                <template #cell(show_details)="row">
                  <b-form-checkbox
                    v-model="row.detailsShowing"
                    plain
                    class="vs-checkbox-con"
                    @change="row.toggleDetails"
                  >
                    <span class="vs-checkbox">
                      <span class="vs-checkbox--check">
                        <i class="vs-icon feather icon-check" />
                      </span>
                    </span>
                    <span class="vs-label">{{
                      row.detailsShowing ? "Hide" : "Show"
                    }}</span>
                  </b-form-checkbox>
                </template>

                <template #row-details="row">
                  <b-card>
                    <b-row class="">
                      <b-col md="4" class="mb-1">
                        <strong>Tipe Soal : </strong
                        >{{
                          row.item.type == "pg"
                            ? "PG"
                            : row.item.type == "multiple_choice"
                            ? "Multiple Choice"
                            : row.item.type == "benar_salah"
                            ? "Benar Salah"
                            : "Pauli"
                        }}
                      </b-col>
                      <b-col
                        md="4"
                        class="mb-1"
                        v-if="
                          row.item.type == 'pg' ||
                          row.item.type == 'multiple_choice' ||
                          row.item.type == 'benar_salah'
                        "
                      >
                        <strong>Bobot : </strong>{{ row.item.bobot }}
                      </b-col>
                      <b-col
                        md="4"
                        class="mb-1"
                        v-if="row.item.type == 'pauli'"
                      >
                        <strong>Waktu : </strong
                        >{{ row.item.timer_second }} detik
                      </b-col>
                    </b-row>
                    <b-row
                      v-if="
                        row.item.type == 'pg' ||
                        row.item.type == 'multiple_choice' ||
                        row.item.type == 'benar_salah'
                      "
                    >
                      <b-col class="mb-1">
                        <strong>Pertanyaan : </strong>
                        <b-card border-variant="dark">
                          <b-card-text
                            :id="row.item.id"
                            v-html="row.item.content"
                          ></b-card-text>
                        </b-card>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="">
                        <div
                          v-if="
                            row.item.type == 'pg' ||
                            row.item.type == 'multiple_choice' ||
                            row.item.type == 'benar_salah'
                          "
                        >
                          <!-- <b-tabs class=""> -->

                          <div
                            v-for="(data, index) in row.item.options"
                            :key="index"
                          >
                            <b-card border-variant="dark">
                              <!-- <template #title>
                                      <div> -->
                              <b-col>
                                <b-container class="bv-example-row">
                                  <b-row class="justify-content-md-center">
                                    <template v-if="row.item.type == 'pg'">
                                      <b-col
                                        :class="
                                          data.is_correct == 1
                                            ? 'bg-success'
                                            : 'bg-white'
                                        "
                                        md="1"
                                      >
                                        <!-- <p> -->
                                        {{
                                          index + 1 == 1
                                            ? "A. "
                                            : index + 1 == 2
                                            ? "B. "
                                            : index + 1 == 3
                                            ? "C. "
                                            : index + 1 == 4
                                            ? "D. "
                                            : index + 1 == 5
                                            ? "E. "
                                            : "-"
                                        }}
                                      </b-col>
                                      <b-col md="11">
                                        <div
                                          class="mb-1"
                                          :id="data.id"
                                          v-html="data.content"
                                        ></div>
                                        <div v-if="data.point > 0">
                                          <strong
                                            ><i
                                              >Poin Opsi: {{ data.point }}</i
                                            ></strong
                                          >
                                        </div>
                                        <!-- </p> -->
                                      </b-col>
                                    </template>
                                    <template
                                      v-if="
                                        row.item.type == 'multiple_choice' ||
                                        row.item.type == 'benar_salah'
                                      "
                                    >
                                      <b-col
                                        :class="
                                          data.is_correct == 1
                                            ? 'bg-success'
                                            : 'bg-white'
                                        "
                                        md="1"
                                      >
                                        <!-- <p> -->
                                        {{
                                          index + 1 == 1
                                            ? "A. "
                                            : index + 1 == 2
                                            ? "B. "
                                            : index + 1 == 3
                                            ? "C. "
                                            : index + 1 == 4
                                            ? "D. "
                                            : index + 1 == 5
                                            ? "E. "
                                            : "-"
                                        }}
                                      </b-col>
                                      <b-col md="8">
                                        <span
                                          :id="data.id"
                                          v-html="data.content"
                                        ></span>
                                        <!-- </p> -->
                                      </b-col>
                                      <b-col col lg="3">
                                        <div>
                                          <label
                                            class="font-weight-bold"
                                            v-if="index + 1 == 1"
                                            >Jawaban Opsi. A</label
                                          >
                                          <label
                                            class="font-weight-bold"
                                            v-if="index + 1 == 2"
                                            >Jawaban Opsi. B</label
                                          >
                                          <label
                                            class="font-weight-bold"
                                            v-if="index + 1 == 3"
                                            >Jawaban Opsi. C</label
                                          >
                                          <label
                                            class="font-weight-bold"
                                            v-if="index + 1 == 4"
                                            >Jawaban Opsi. D</label
                                          >
                                          <label
                                            class="font-weight-bold"
                                            v-if="index + 1 == 5"
                                            >Jawaban Opsi. E</label
                                          >
                                          <b-form-checkbox
                                            :id="data.id"
                                            v-if="
                                              row.item.type == 'multiple_choice'
                                            "
                                            v-model="data.is_correct"
                                            value="1"
                                            class="custom-control-primary"
                                          >
                                            Kunci Jawaban
                                          </b-form-checkbox>
                                        </div>
                                        <div
                                          v-if="row.item.type == 'benar_salah'"
                                        >
                                          {{
                                            data.is_true_or_false == "benar"
                                              ? "Benar"
                                              : "Salah"
                                          }}
                                        </div>
                                      </b-col>
                                    </template>
                                  </b-row>
                                </b-container>
                              </b-col>
                            </b-card>
                          </div>
                        </div>
                        <div v-if="row.item.type == 'pauli'">
                          <!-- <b-tabs class=""> -->

                          <div
                            v-for="(data, index) in row.item.sub_soals"
                            :key="index"
                          >
                            <!-- <b-col> -->
                            <b-container class="bv-example-row">
                              <b-row class="justify-content-md-center">
                                <b-col md="2">
                                  <b-card border-variant="dark">
                                    <span
                                      :id="data.id"
                                      v-html="data.content"
                                    ></span>
                                  </b-card>
                                </b-col>
                                <b-col md="2">
                                  <b-card border-variant="dark">
                                    <span
                                      :id="data.id"
                                      v-html="data.secret_key"
                                    ></span>
                                  </b-card>
                                </b-col>
                              </b-row>
                            </b-container>
                            <!-- </b-col> -->
                          </div>
                        </div>
                      </b-col>
                    </b-row>

                    <b-button
                      size="sm"
                      variant="outline-secondary"
                      @click="row.toggleDetails"
                      class="mt-1"
                    >
                      Hide Details
                    </b-button>
                  </b-card>
                </template>
                <template #cell(show_details2)="row">
                  <b-form-checkbox
                    v-model="row.detailsShowing"
                    plain
                    class="vs-checkbox-con"
                    @change="row.toggleDetails"
                  >
                    <span class="vs-checkbox">
                      <span class="vs-checkbox--check">
                        <i class="vs-icon feather icon-check" />
                      </span>
                    </span>
                    <span class="vs-label">{{
                      row.detailsShowing ? "Hide" : "Show"
                    }}</span>
                  </b-form-checkbox>
                </template>

                <template #row-details2="row">
                  <b-card>
                    <b-row class="">
                      <b-col md="4" class="mb-1">
                        <strong>Tipe Soal : </strong>{{ row.item.type }}
                      </b-col>
                      <b-col md="4" class="mb-1">
                        <strong>Bobot : </strong>{{ row.item.bobot }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="mb-1">
                        <strong>Pertanyaan : </strong>
                        <quill-editor
                          :id="row.item.id"
                          v-model="row.item.content"
                          :options="editor_options"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <div
                        v-if="
                          row.item.type == 'pg' ||
                          row.item.type == 'multiple_choice' ||
                          row.item.type == 'benar_salah'
                        "
                      >
                        <b-tabs class="mt-2">
                          <div
                            v-for="(data, index) in row.item.options"
                            :key="index"
                          >
                            <b-tab>
                              <template #title>
                                <div>
                                  <label
                                    class="font-weight-bold"
                                    v-if="index + 1 == 1"
                                    >Opsi. A</label
                                  >
                                  <label
                                    class="font-weight-bold"
                                    v-if="index + 1 == 2"
                                    >Opsi. B</label
                                  >
                                  <label
                                    class="font-weight-bold"
                                    v-if="index + 1 == 3"
                                    >Opsi. C</label
                                  >
                                  <label
                                    class="font-weight-bold"
                                    v-if="index + 1 == 4"
                                    >Opsi. D</label
                                  >
                                  <label
                                    class="font-weight-bold"
                                    v-if="index + 1 == 5"
                                    >Opsi. E</label
                                  >
                                </div>
                              </template>
                              <b-form>
                                <b-row>
                                  <b-col>
                                    <quill-editor
                                      id="Konten"
                                      v-model="data.content"
                                      :options="editor_options"
                                    />
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col
                                    md="2"
                                    v-if="
                                      row.item.type == 'multiple_choice' ||
                                      row.item.type == 'benar_salah'
                                    "
                                  >
                                    <label
                                      class="font-weight-bold mt-2"
                                      v-if="index + 1 == 1"
                                      >Jawaban Opsi. A</label
                                    >
                                    <label
                                      class="font-weight-bold mt-2"
                                      v-if="index + 1 == 2"
                                      >Jawaban Opsi. B</label
                                    >
                                    <label
                                      class="font-weight-bold mt-2"
                                      v-if="index + 1 == 3"
                                      >Jawaban Opsi. C</label
                                    >
                                    <label
                                      class="font-weight-bold mt-2"
                                      v-if="index + 1 == 4"
                                      >Jawaban Opsi. D</label
                                    >
                                    <label
                                      class="font-weight-bold mt-2"
                                      v-if="index + 1 == 5"
                                      >Jawaban Opsi. E</label
                                    >
                                    <b-form-checkbox
                                      v-if="row.item.type == 'multiple_choice'"
                                      v-model="data.is_correct"
                                      value="1"
                                      class="custom-control-primary"
                                    >
                                      Kunci Jawaban
                                    </b-form-checkbox>
                                    <b-form-radio-group
                                      v-if="row.item.type == 'benar_salah'"
                                      v-model="data.is_true_or_false"
                                      :options="bsSoal"
                                      name="radios-stacked"
                                      stacked
                                    />
                                  </b-col>
                                  <!-- <b-col md="10" v-if="row.item.type != 'pg'">
                    <label class="font-weight-bold mt-2"
                      >Upload file format: jpg, png, pdf, doc, mp3,
                      mp4 (opsional)</label
                    >
                    <b-form-file
                      :id="'file' + data.id"
                      class="mb-2"
                      v-model="data.opsi_file"
                      placeholder="Pilih file untuk di upload atau unggah..."
                      drop-placeholder="Drop file here..."
                      no-drop
                      accept=".jpg, .png, .pdf, .doc, .mp3, .mp4"
                    />
                  </b-col> -->
                                  <!-- <b-col md="12" v-if="row.item.type == 'pg'">
                    <label class="font-weight-bold mt-2"
                      >Upload file format: jpg, png, pdf, doc, mp3,
                      mp4 (opsional)</label
                    >
                    <b-form-file
                      :id="'file' + data.id"
                      class="mb-2"
                      v-model="data.opsi_file"
                      placeholder="Pilih file untuk di upload atau unggah..."
                      drop-placeholder="Drop file here..."
                      no-drop
                      accept=".jpg, .png, .pdf, .doc, .mp3, .mp4"
                    />
                  </b-col> -->
                                </b-row>
                              </b-form>
                            </b-tab>
                          </div>
                        </b-tabs>
                      </div>
                    </b-row>

                    <b-button
                      size="sm"
                      variant="outline-secondary"
                      @click="row.toggleDetails"
                    >
                      Hide Details
                    </b-button>
                  </b-card>
                </template>
                <!-- A custom formatted column -->
                <template #cell(tgl)="data">
                  {{ humanDate(data.item.info_start) }} s/d
                  {{ humanDate(data.item.info_end) }}
                </template>

                <template #cell(check)="data">
                  <b-form-checkbox :value="data.item" v-model="select_soal" />
                  <!-- <b-form-checkbox :value="data.item" v-model="select_soal" /> -->
                </template>
                <template #cell(max_quota)="data">
                  {{ formatRupiah(data.item.max_quota) }}
                </template>

                <template #cell(color)="data">
                  <b-badge :variant="data.item.color">
                    {{ data.item.color }}
                  </b-badge>
                </template>
                <template #cell(type)="data">
                  {{
                    data.item.type == "pg"
                      ? "PG"
                      : data.item.type == "multiple_choice"
                      ? "Multiple Choice"
                      : data.item.type == "benar_salah"
                      ? "Benar Salah"
                      : "Pauli"
                  }}
                </template>
              </b-table>

              <b-pagination
                v-if="soalData.length >= 1"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
              <b-card
                body-class="text-center"
                text-variant="danger"
                border-variant="danger"
                class="mt-1"
                v-if="soalData.length == 0 && filterMapel != null"
              >
                Soal belum ada, silahkan pilih mata pelajaran yang lainnya
              </b-card>
              <b-card
                text-variant="danger"
                body-class="text-center"
                border-variant="danger"
                class="mt-1"
                v-if="filterPaket == null"
              >
                Silakan pilih kategori paket terlebih dahulu untuk menampilkan
                soal
              </b-card>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>

      <b-modal
        id="modal-ujian-di-soal"
        size="lg"
        no-close-on-backdrop
        no-close-on-esc
      >
        <section>
          <p
            class="text-primary mb-2"
            v-if="currentSoal && soalUjians.length < 1"
          >
            Silahkan klik tombol <strong>"Hapus Soal"</strong>
          </p>
          <p
            class="text-danger mb-2"
            v-if="currentSoal && soalUjians.length > 0"
          >
            <i
              >Soal <strong>{{ currentSoal?.name }}</strong> sudah ditempatkan
              di ujian-ujian berikut:</i
            >
          </p>
          <table
            border="1"
            class="w-100 table mb-2"
            v-if="currentSoal && soalUjians.length > 0"
          >
            <tr>
              <th>Nama Ujian</th>
              <th>Kategori Paket</th>
              <th class="text-center">Jumlah Soal</th>
              <th class="text-center">Hapus Soal Dari Ujian</th>
            </tr>
            <tr v-for="(ujian, i) in soalUjians" :key="i">
              <th>{{ ujian?.name }}</th>
              <th>
                <strong>
                  <i
                    :class="
                      ujian?.category_paket ? 'text-success' : 'text-danger'
                    "
                  >
                    {{
                      ujian.category_paket
                        ? ujian.category_paket?.name
                        : "Tidak ditemukan"
                    }}
                  </i>
                </strong>
              </th>
              <th class="text-center">{{ ujian?.sum_soal }}</th>
              <th class="text-center">
                <feather-icon
                  icon="MinusCircleIcon"
                  size="24"
                  class="text-danger cursor-pointer"
                  @click="deleteSoalFromUjian(currentSoal?.id, ujian?.id)"
                ></feather-icon>
              </th>
            </tr>
          </table>
          <div v-if="currentSoal && soalUjians.length > 0">
            <strong>Keterangan:</strong>
            <div class="d-flex align-items-center">
              <b-alert class="p-1" show variant="warning">
                Silahkan hapus soal {{ currentSoal?.name }} dari ujian-ujian
                diatas dan lakukan penghapusan ulang soal tersebut.
              </b-alert>
            </div>
          </div>
        </section>

        <template #modal-footer>
          <button
            class="btn btn-primary"
            :disabled="soalUjians.length > 0"
            @click.prevent="ModalHapus(currentSoal)"
          >
            Hapus Soal
          </button>
        </template>
      </b-modal>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BOverlay,
  BAlert,
  BFormTextarea,
  BFormFile,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  VBTooltip,
  BLink,
  BDropdownItem,
  BDropdown,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

export default {
  components: {
    BDropdownItem,
    BDropdown,
    BOverlay,
    BAlert,
    BFormTextarea,
    BFormFile,
    flatPickr,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    VBTooltip,
    BLink,
    ToastificationContent,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      filterKatPaket: null,
      optKatPaket: [],
      filterPaket: null,
      optPaket: [],
      filterMapel: null,
      optMapel: [],
      filterMapel: null,
      optMapel: [],
      filterTipe: null,
      optTipe: [
        { id: "pg", value: "Pilihan Ganda" },
        { id: "multiple_choice", value: "Multiple Choice" },
        { id: "benar_salah", value: "Benar Salah" },
        { id: "pauli", value: "Pauli" },
      ],
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      soalData: [],
      katsoalData: [],
      form: {
        id: null,
        name: null,
        price: null,
        thumbnail: null,
        description: null,
        info_start: new Date(),
        info_end: new Date(),
        max_quota: null,
        sum_subtest: null,
      },
      //Modal
      titleModal: null,
      Modal: false,
      ModalUploadThumbnail: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "index", label: "No" },
        { key: "name", label: "Judul Soal", sortable: true },
        { key: "type", label: "Tipe", sortable: true },
        { key: "kategori_paket.name", label: "Kategori Paket", sortable: true },
        { key: "bobot", label: "Bobot", sortable: true },
        { key: "show_details", label: "Detail" },
        { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "name", value: "Paket" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      search: null,
      idd: null,
      loading: false,
      soalUjians: [],
      currentSoal: null,
    };
  },
  watch: {
    perPage(value) {
      if (value) {
        this.getData();
      }
    },
    filter(keyword) {
      if (keyword.length > 2 || keyword.length == 0) {
        this.getData();
      }
    },
    currentPage(val) {
      if (val) {
        this.getData();
      }
    },
    filterKatPaket(categoryPaket) {
      if (categoryPaket) {
        this.getDataMapel();
        this.getData();
      } else {
        this.soalData = [];
      }
      this.filterMapel = null;
      this.filterTipe = null;
    },
    filterMapel(mapel) {
      if (this.filterKatPaket) {
        this.getData();
      }
    },
    filterTipe(tipe) {
      if (this.filterKatPaket) {
        this.getData();
      }
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    async deleteSoalFromUjian(soal_id, ujian_id) {
      const payload = [{ soal_id, ujian_id }];
      try {
        await this.$store.dispatch("banksoal/rmSoalUjian", payload);
        let oriSoalUjians = Object.assign([], this.soalUjians);
        oriSoalUjians = oriSoalUjians.filter((ujian) => ujian.id != ujian_id);
        this.soalUjians = oriSoalUjians;
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
    doFormatRupiah() {
      this.form.price = this.formatRupiah(this.form.price);
      this.form.max_quota = this.formatRupiah(this.form.max_quota);
      this.form.sum_subtest = this.formatRupiah(this.form.sum_subtest);
    },
    tutupModal() {
      this.Modal = false;
      this.resetForm();
      this.getData();
    },
    resetForm() {
      this.form = {
        id: null,
        name: null,
        description: null,
        thumbnail: null,
        description: null,
        info_start: new Date(),
        info_end: new Date(),
        max_quota: null,
        sum_subtest: null,
      };
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ModalUpload(item) {
      this.id = item.id;
      this.form = item;
      this.doFormatRupiah();
      this.activeAction = "upload";
      this.titleModal = "Upload Thumbnail";
      this.ModalUploadThumbnail = true;
    },
    ModalShow() {
      this.doFormatRupiah();
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Paket";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "ubah";
      this.doFormatRupiah();
      this.titleModal = "Ubah Paket";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$bvModal.hide("modal-ujian-di-soal");
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const fd = new FormData();
          fd.append(`data[0][id]`, data.id);
          fd.append(`data[0][fungsi]`, (data.fungsi = 1));
          // data.fungsi = 1; //soft delete
          this.$store
            .dispatch("banksoal/createUpdateDelete", fd)
            .then(() => {
              this.getData();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
              this.$swal({
                icon: "success",
                title: "Terhapus!",
                text: "Data berhasil dihapus.",
                // timer: 1000,
                allowOutsideClick: true,
                customClass: {
                  confirmButton: "btn btn-success",
                },
                buttonsStyling: false,
              });
            })
            .catch((e) => {
              const dataError = e.response?.data;
              if (
                dataError &&
                dataError?.data_ujian &&
                dataError?.data_ujian.length > 0
              ) {
                const { data_ujian: listUjian } = dataError;
                this.currentSoal = data;
                this.soalUjians = listUjian;
                this.$bvModal.show("modal-ujian-di-soal");
              } else {
                this.displayError(e);
                return false;
              }
            });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    getData() {
      this.loading = true;
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      let payload = {
        filter_by: "name",
        user_id: this.user.id,
        search: this.filter != null ? this.filter : null,
        order: "desc",
        start: currentPage,
        length: this.perPage,
      };

      if (this.filterKatPaket) {
        payload.category_paket_id = this.filterKatPaket?.value;
      }
      // if (this.filterPaket) {
      //   payload.paket_id = this.filterPaket?.value;
      // }

      if (this.filterMapel) {
        payload.ujian_id = this.filterMapel?.value;
      }

      if (this.filterTipe) {
        payload.type = this.filterTipe?.id;
      }

      this.$store
        .dispatch("banksoal/index", payload)
        .then((response) => {
          this.loading = false;
          const { data, totalRecords } = response.data;
          // let soalData = response.data.data;
          this.soalData = data;
          this.totalRows = totalRecords;
          // this.doFormatRupiah();
        })
        .catch((error) => {
          this.loading = false;
          this.displayError(error);
          return false;
          // this.$root.$emit("errorData", error);
        });
    },
    async getDataKategoriPaket() {
      const payload = {};
      this.$store
        .dispatch("paketTryout/indexKategoriPaket", payload)
        .then((response) => {
          let optKatPaket = response.data.data;
          optKatPaket.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optKatPaket = optKatPaket;

          // jika sebelumnya sudah milih kategori
          if (this.$route.query?.cat) {
            const prevSelectedKatId = this.$route.query?.cat;
            this.filterKatPaket = optKatPaket.find(
              (item) => item.value == prevSelectedKatId
            );
          }
          // this.totalRows = this.katsoalData.length;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    async getDataPaket() {
      const payload = {
        category_id:
          this.filterKatPaket != null ? this.filterKatPaket.id : null,
      };
      this.$store
        .dispatch("paketTryout/index", payload)
        .then((response) => {
          let optPaket = response.data.data;
          optPaket.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optPaket = optPaket;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    async getDataMapel() {
      const payload = {
        user_id: this.user.id,
        category_paket_id:
          this.filterPaket != null ? this.filterPaket.value : null,
      };
      this.$store
        .dispatch("adminujian/index", payload)
        .then((response) => {
          // this.loading = false;
          let optMapel = response.data.data;
          optMapel.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optMapel = optMapel;
        })
        .catch((error) => {
          // this.loading = false;fthi
          this.$root.$emit("errorData", error);
        });
    },
  },
  async mounted() {
    this.getDataKategoriPaket();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
